<template>
  <v-select
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="Id"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
    chips
    deletable-chips
    multiple
    dense
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
    <template v-slot:prepend-item v-if="showSelectAll">
  <v-list-item ripple @click="selectAllItems">
    <v-list-item-action>
      <v-icon :color="availableItems.length > 0 ? 'primary' : ''">
        
      </v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        Select All
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-divider class="mt-2"></v-divider>
</template>
  </v-select>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  export default {
    name: 'select-multi-id-luPeriods',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Periods',
        },
      value: Array,
      clearable: {
        type: Boolean,
        default: false,
      },
      showSelectAll: {
        type: Boolean,
        default: false,
      },
      includeInactive: {
        type: Boolean,
        default: false,
      },
      rules: Array,
      items: Array,
      //templateFIX: if there is more than one pick one to display
      itemText: {
        type: String,
      },
      sortProperty: {
        type: String,
        default: null,
      },
    },
    data(){
      return {
      }
    },
    computed: {
      ...get('luPeriod',['luPeriods', 'loading']),
      icon() {
      if (this.selected.length == this.availableItems.length)
        return "mdi-close-box";
      if (this.selected.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
      availableItems() {
          var list = []
          var fullList = []
          if (this.items) {
            fullList = this.items
            list = this.items.filter((i) => true)
          } else {
            fullList = this.luPeriods
            list = this.luPeriods.filter((i) => true)
          }
          // if existing record make sure the previous selection is available even if no longer active
          if (this.value > 0) {
            var selectedItem = fullList.find((i) => i.Id == this.value)
            if (!list.find((i) => i.Id == this.value) && selectedItem) {
              list.push(selectedItem)
            }
          }
          // sort by itemText column
          list.sort((a, b) => {
            //set the property to sort on
            let textField = this.sortProperty || this.itemText
            let sort = a[textField] > b[textField] ? 1 : -1
            return sort
          })
          return list
      },
      selected: {
        get() {
          if (this.value && this.value.length > 0) {
            return this.value
          } else {
            return []
          }
        },
        set(newValue) {
          this.$emit('change', newValue)
        }
      }
    },
    created(){
      if (!this.items) {
        this.loadLuPeriods()
      }
    },
    methods: {
      loadLuPeriods: call('luPeriod/loadLuPeriods'),
      selectAllItems() {
      this.$nextTick(() => {
        if (this.selected.length == this.availableEmployees.length) {
          this.selected = [];
        } else {
          this.selected = [...this.availableItems];
        }
      });
    },
    },
  }
</script>
<style>

</style>