<template>
  <div>
    <v-icon class="clickable" scrollable @click="reorderFieldDialog = true" color="primary"
      >reorder</v-icon
    >

    <v-dialog v-model="reorderFieldDialog" width="450">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Reorder Fields</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="12">
              <draggable v-model="fields">
                <div v-for="(field, index) in fields" :key="index">
                  <span style="cursor: move">{{ field }}</span>
                </div>
              </draggable>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="reorderFieldDialog = false" color="primary"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  model: {
    prop: 'selectedFields',
    event: 'change',
  },
  props: {
    selectedFields: Array,
  },
  components: {
    draggable,
  },
  filters: {},
  data() {
    return {
      reorderFieldDialog: false,
      fields: this.selectedFields,
    }
  },
  created() {},
  computed: {},
  methods: {},
  watch: {
    selectedFields: {
      handler(v) {
        this.fields = this.selectedFields
      },
      deep: true,
    },
    fields: {
      handler(v) {
        this.$emit('change', v)
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
</style>