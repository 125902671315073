<template>
  <v-container>
    <v-tabs>
      <v-tab>AdHoc</v-tab>
      <v-tab-item>
        <VwApplicationReportGrid></VwApplicationReportGrid>
      </v-tab-item>
      <v-tab>Canned</v-tab>
      <v-tab-item>
        <CannedReportsGrid></CannedReportsGrid>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import VwApplicationReportGrid from '@components/reports/VwApplicationReportGrid'
import CannedReportsGrid from '@components/reports/cannedReportsGrid'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    VwApplicationReportGrid,
    CannedReportsGrid,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
