<template>
  <v-col>
    <BaseTooltipButton
      buttonClass="mx-1"
      v-for="(fileType, index) in sortedFileTypes"
      :key="index"
      @click="$emit('downloadReport', fileType)"
      iconColor="primary"
      :iconButton="false"
      :icon="fileTypeIcon(fileType)"
      buttonRightText="Download"
    >
      download {{ fileType.Description }}
    </BaseTooltipButton>
  </v-col>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    fileTypes: {
      type: Array,
      required: true,
    },
  },
  components: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    sortedFileTypes() {
      return this.fileTypes
        .filter((f) => true)
        .sort((a, b) => {
          return a.Id - b.Id
        })
    },
  },
  methods: {
    fileTypeIcon(fileType) {
      switch (fileType.Extension) {
        case 'xlsx':
          return 'mdi-microsoft-excel'
        case 'pdf':
          return 'mdi-file-pdf-box'
        case 'html':
          return 'mdi-web-box'
      }
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>