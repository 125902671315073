<template>
  <BasePanelWithHeader headerText="Canned Reports">
    <v-row>
      <v-col>
        <v-select
          label="Select Report"
          :items="availableCannedReports"
          return-object
          v-model="selectedCannedReport"
          item-text="Description"
        >
        </v-select>
      </v-col>
      <downloadButtons
        v-if="
          selectedCannedReport.FileTypes &&
          selectedCannedReport.FileTypes.length > 0
        "
        :fileTypes="selectedCannedReport.FileTypes"
        @downloadReport="downloadReport"
      ></downloadButtons>
    </v-row>
    <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
      <reportParams
        v-if="reportParameters.length > 0"
        :reportParameters="reportParameters"
        :searchParams="searchParams"
        @updateSearchParam="updateSearchParam"
      ></reportParams>
    </v-form>
    <BaseServerSyncDataTable
      v-if="selectedCannedReport.Id"
      :headers="filteredHeaders"
      :items="gridData"
      :searchTotal="searchTotal"
      :loading="loading"
      :searchSortBy.sync="searchSortBy"
      :searchDescending.sync="searchDescending"
      :searchPage.sync="searchPage"
      :searchRowsPerPage.sync="searchRowsPerPage"
      multi-sort
      :show-group-by="showGroupBy"
      :groupBy.sync="groupBy"
      :groupDesc.sync="groupByDescending"
    >
      <template
        v-for="(format, field) in mappedFormats"
        v-slot:[field]="{ item }"
        >{{ formatItemField(item, field, format) }}</template
      >
    </BaseServerSyncDataTable>
  </BasePanelWithHeader>
</template>
<script>
import reportParams from '@components/reports/reportParameters'
import downloadButtons from '@components/reports/downloadButtons'
import { get, sync, commit, call } from 'vuex-pathify'
import { authComputed } from '@state/helpers'
import download from 'downloadjs'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat) // needed for custom date parsing
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat) // needed to use 'L' as a format option
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    reportParams,
    downloadButtons,
  },
  data() {
    return {
      searchParams: {},
      cannedReports: [],
      valid: false,
      selectedMonth: 0,
      selectedYear: 0,
      includeDetails: false,
      startDate: null,
      endDate: null,
    }
  },
  created() {
    this.getCannedReportList()
    this.loadLuPeriods()
  },
  watch: {
    reportParameters(nval, oval) {
      this.setDefaultParameters()
    },
    selectedCannedReport(nval, oval) {
      this.loadCannedReportGridData()
    },
  },
  computed: {
    ...get('luPeriod', ['luPeriods']),
    ...get('cannedReport', [
      'reportParameters',
      'gridData', //entries in reportresult
      'loading',
      'headerItems', // from reportresult
      'formatFields', // from reportresult
    ]),
    ...sync('cannedReport', [
      'selectedCannedReport',
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'groupByProperty',
      'groupByDescending',
      'searchRowsPerPage',
    ]),
    searchStartDate: sync('cannedReport/startDate'),
    searchEndDate: sync('cannedReport/endDate'),
    searchPeriodId: sync('cannedReport/periodId'),
    availableCannedReports() {
      return this.cannedReports.filter((x) => x.IsActive)
    },
    headers() {
      var groupByProperties = this.selectedCannedReport.reportParameters.find(
        (i) => i.name == 'groupByProperty'
      )
      if (
        groupByProperties &&
        groupByProperties.options &&
        groupByProperties.options.list
      ) {
        groupByProperties = groupByProperties.options.list
      }
      let headers = this.headerItems.map((item) => {
        return {
          text: this.splitTitleCase(item),
          align: 'center',
          value: item,
          visible: true,
          groupable: groupByProperties
            ? groupByProperties.findIndex((i) => i.stringValue == item) > -1
            : false,
          //todo: set sortable property
        }
      })
      return headers
    },
    showGroupBy() {
      return this.headers.filter((x) => x.groupable).length > 0
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    mappedFormats() {
      let slotFormat = {}
      for (var key in this.formatFields) {
        if (this.formatFields.hasOwnProperty(key)) {
          slotFormat[`item.${key}`] = this.formatFields[key]
        }
      }
      return slotFormat
    },
    reportParameters() {
      if (
        this.selectedCannedReport &&
        this.selectedCannedReport.reportParameters
      ) {
        let params = JSON.parse(
          JSON.stringify(this.selectedCannedReport.reportParameters)
        )
        params.forEach((p) => {
          var list = []
          if (p.options && p.options.list && p.options.list.length > 0) {
            p.options.list = p.options.list.map((i) => {
              return {
                text: i.text,
                value: i.value ? i.value : i.stringValue,
              }
            })
          } else {
            if (p.name == 'periodId') {
              list = JSON.parse(
                JSON.stringify(
                  this.luPeriods.map((x) => {
                    return { ...x, text: x.Period, value: x.Id }
                  })
                )
              )
            }
            if (list.length > 0) {
              p.options.list = this.filterList(p, list)
            }
          }
        })
        return params
      } else {
        return []
      }
    },
    needsParameters() {
      if (this.reportParameters && this.reportParameters.length > 0) {
        return true
      } else {
        return false
      }
    },
    groupBy: {
      get() {
        if (this.groupByProperty) {
          return [this.groupByProperty]
        } else {
          return []
        }
      },
      set(nval) {
        let oval = this.groupByProperty
        if (nval) {
          if (Array.isArray(nval)) {
            if (nval.length > 0) {
              this.groupByProperty = nval[0]
            } else {
              this.groupByProperty = null
            }
          } else {
            this.groupByProperty = nval
          }
        } else {
          this.groupByProperty = null
        }
      },
    },
  },
  methods: {
    ...call('luPeriod', ['loadLuPeriods']),
    handleError: call('errors/handleError'),
    ...call('cannedReport', [
      'loadCannedReportGridData',
      'downloadCannedReport',
    ]),
    filterList(param, list) {
      if (list && list.length > 0) {
        if (
          param &&
          param.options &&
          param.options.filterList &&
          param.options.filterList.length > 0
        ) {
          param.options.filterList.forEach((x) => {
            if (x.valueMatch) {
              let matchList = JSON.parse(x.valueMatch)
              if (matchList && matchList.length > 0) {
                list = list.filter((i) => matchList.includes(i[x.propertyName]))
              }
            }
          })
        }
        return list.map((x) => {
          return {
            text: x.text || x.Description,
            value: x.Id,
          }
        })
      }
    },

    getCannedReportList() {
      let url = `/CannedReport`
      this.$axios.get(url).then(
        (res) => {
          this.cannedReports = res.data
        },
        (error) => {
          this.handleError(error)
        }
      )
    },
    setDefaultParameters() {
      this.reportParameters.forEach((item) => {
        if (!this.searchParams[item.name] && item.defaultValue) {
          this.searchParams[item.name] = item.defaultValue
        }
      })
      if (this.searchParams.startDate) {
        this.searchStartDate = this.searchParams.startDate
      }
      if (this.searchParams.endDate) {
        this.searchEndDate = this.searchParams.endDate
      }
    },
    updateSearchParam(p) {
      if (p.name == 'startDate') {
        this.searchStartDate = p.val
      } else if (p.name == 'endDate') {
        this.searchEndDate = p.val
      } else if (p.name == 'periodId') {
        this.searchPeriodId = p.val
      } else if (p.name == 'groupByProperty') {
        this.groupByProperty = p.val
      }
    },
    async validate() {
      this.$refs.form.validate()
    },
    downloadReport(fileType) {
      this.validate().then(() => {
        if (this.valid) {
          this.downloadCannedReport({ selectedFileTypeId: fileType.Id })
        }
      })
    },
    formatDate(item, formatTo, formatFrom) {
      return dayjs(item, formatFrom).format(formatTo)
    },
    formatPercent(item, decimalPlaces = 2) {
      return (item * 100).toFixed(decimalPlaces) + '%'
    },
    formatDecimal(item, decimalPlaces = 2) {
      return item.toFixed(decimalPlaces)
    },
    formatMoney(item, decimalPlaces = 2) {
      let amt = parseFloat(item.toFixed(decimalPlaces))
      if (amt < 0) {
        return (
          '(' +
          ((amt &&
            Math.abs(amt)
              .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) ||
            '0') +
          ')'
        )
      }
      return (
        (amt &&
          amt
            .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) ||
        '0'
      )
    },
    splitTitleCase(input) {
      var split = input.replace(/[_-]/g, ' ')
      split = split.replace(/(\B[A-Z][a-z])/g, ' $1')
      split = split.replace(/([a-z])([A-Z])/g, '$1 $2')
      split = split.replace(/([^\d\s])([\d])/g, '$1 $2')
      split = split.replace(/([\d])([^\d\s])/g, '$1 $2')
      split = split.replace(/^l\s?u\b\s/gi, '')
      split = split.replace(/^v\s?w\b\s/gi, '')
      split = split.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
      })
      // console.blue(input, split)
      return split
    },
    formatItemField(item, field, format) {
      //get the actual field name after we added the "item." to the front of it for the v-slot
      var fieldName = field
      var fieldSplit = field.split('.')
      if (fieldSplit[1]) {
        fieldName = fieldSplit[1]
      }
      var splitFormat = format.split('|')
      switch (splitFormat[0].toLowerCase()) {
        case 'date':
          var formatFrom = undefined
          var formatTo = 'L'
          if (splitFormat[1]) {
            formatTo = splitFormat[1]
          }
          if (splitFormat[2]) {
            formatFrom = splitFormat[2]
          }
          if (item[fieldName] && item[fieldName].trim()) {
            return this.formatDate(item[fieldName], formatTo, formatFrom)
          } else {
            return ''
          }
        case 'percent':
          if (item[fieldName]) {
            return this.formatPercent(item[fieldName], splitFormat[1])
          } else {
            return ''
          }
        case 'decimal':
          if (item[fieldName]) {
            return this.formatDecimal(item[fieldName], splitFormat[1])
          } else {
            return ''
          }
        case 'money':
          if (item[fieldName]) {
            return this.formatMoney(item[fieldName], splitFormat[1])
          } else {
            return ''
          }
        default:
          return item[fieldName]
      }
    },
  },
}
</script>
<style scoped></style>
