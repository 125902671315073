export default class SavedQuery {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //UserId is required
      UserId: 0,
      //Report is required
      Report: '',
      //Title is required
      Title: '',
      //Query is required
      Query: '',
      IsShared: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.UserId = obj.UserId
    this.Report = obj.Report
    this.Title = obj.Title
    this.Query = obj.Query
    this.IsShared = obj.IsShared
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    return obj
  }
}
