export const VwApplicationReportsStatic = [
  {
    Id: -1,
    Title: 'Mailing List',
    Query: {
      sortProperty: ['ApplicationId'],
      sortDirection: ['desc'],
      groupByProperty: null,
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'FirstName',
        'MiddleInitial',
        'LastName',
        'MailingAddress1',
        'MailingAddress2',
        'MailingCity',
        'MailingState',
        'MailingZip',
      ],
      queryName: 'Mailing List',
      queryIsModified: false,
      pivotOptions: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
      startDate: null,
      endDate: null,
      statusId: [6, 4],
      reasonId: [],
      countyId: [],
      periodId: [],
    },
  },
  {
    Id: -2,
    Title: 'Refunds County Individual',
    Query: {
      sortProperty: ['LastName'],
      sortDirection: ['asc'],
      groupByProperty: 'County',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'County',
        'LastName',
        'FirstName',
        'MiddleInitial',
        'WolfsPaidAmount',
      ],
      queryName: 'Refunds County Individual',
      queryIsModified: false,
      pivotOptions: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
      startDate: null,
      endDate: null,
      statusId: [6],
      reasonId: [],
      countyId: [],
      periodId: [],
    },
  },
  {
    Id: -3,
    Title: 'Refunds County Individual No Name',
    Query: {
      sortProperty: ['LastName'],
      sortDirection: ['asc'],
      groupByProperty: 'County',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: ['County', 'WolfsPaidAmount'],
      queryName: 'Refunds County Individual No Name',
      queryIsModified: false,
      pivotOptions: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
      startDate: null,
      endDate: null,
      statusId: [6],
      reasonId: [],
      countyId: [],
      periodId: [],
    },
  },
  {
    Id: -4,
    Title: 'Rejects County Individual',
    Query: {
      sortProperty: ['LastName'],
      sortDirection: ['asc'],
      groupByProperty: 'County',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'County',
        'LastName',
        'FirstName',
        'MiddleInitial',
        'ReasonNumber',
        'ReasonDescription',
        'ReasonType',
      ],
      queryName: 'Rejects County Individual',
      queryIsModified: false,
      pivotOptions: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
      startDate: null,
      endDate: null,
      statusId: [4],
      reasonId: [],
      countyId: [],
      periodId: [],
    },
  },
  {
    Id: -5,
    Title: 'Rejects County Individual No Name',
    Query: {
      sortProperty: ['LastName'],
      sortDirection: ['asc'],
      groupByProperty: 'County',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'County',
        'ReasonNumber',
        'ReasonDescription',
        'ReasonType',
      ],
      queryName: 'Rejects County Individual No Name',
      queryIsModified: false,
      pivotOptions: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
      startDate: null,
      endDate: null,
      statusId: [4],
      reasonId: [],
      countyId: [],
      periodId: [],
    },
  },
]
