<template>
  <v-row>
    <v-col sm="12" md="3" v-for="(p, index) in mappedParameters" :key="index">
      <span v-if="p.type == 'Date' || p.type == 'DateTime'">
        <BaseDatePickerWithText
          :label="p.label"
          v-model="p.value"
          @change="updateValue($event, p)"
          :clearable="!p.required"
        ></BaseDatePickerWithText>
      </span>
      <span v-if="p.type == 'text'">
        <v-text-field
          @change="updateValue($event, p)"
          v-model="p.model"
          :label="p.label"
          clearable
        ></v-text-field>
      </span>
      <span v-if="p.type == 'lookup' && p.options.list">
        <v-select
          :label="p.label"
          v-model="p.value"
          :items="p.options.list"
          @change="updateValue($event, p)"
          :chips="p.options.multiple"
          :deletable-chips="p.options.multiple"
          :small-chips="p.options.multiple"
          :multiple="p.options.multiple"
          :clearable="!p.required"
          :rules="p.required ? [(v) => !!v || 'required'] : undefined"
        ></v-select>
      </span>
    </v-col>
    <v-col v-for="item in groupValidations">
      <v-input :rules="[item.rule]"></v-input>
    </v-col>
  </v-row>
</template>

<script>
import {authComputed} from '@state/helpers'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    reportParameters: {
      type: Array,
      required: true,
    },
    staticParams: {
      type: Array,
      default: () => [],
    },
    searchParams: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  filters: {},
  data() {
    return {
      update: 0,
    }
  },
  created() {},
  computed: {
    ...authComputed,
    groupValidations() {
      var groupRequired = {}
      var groupValidate = []
      this.reportParameters
        .filter((x) => !this.staticParams.includes(x.name) && x.requiredGroup)
        .forEach((p) => {
          if (!groupRequired[p.requiredGroup]) {
            groupRequired[p.requiredGroup] = [{name: p.name, label: p.label}]
          } else {
            groupRequired[p.requiredGroup].push({name: p.name, label: p.label})
          }
        })
      if (Object.keys(groupRequired).length > 0) {
        Object.keys(groupRequired).forEach((g) => {
          var groupHasParam = false
          groupRequired[g].forEach((f) => {
            if (this.searchParams[f.name]) {
              groupHasParam = true
            }
          })
          if (!groupHasParam) {
            var message = `One of the following is required: ${groupRequired[g]
              .map((x) => x.label)
              .join(', ')}`
            groupValidate.push({rule: message})
          }
        })
      }
      return groupValidate
    },
    mappedParameters: {
      get() {
        //this is a 'hack' to force the update of the computed
        this.update
        return this.reportParameters
          .filter((x) => !this.staticParams.includes(x.name))
          .map((item) => {
            return {
              ...item,
              value: this.searchParams[item.name],
            }
          })
      },
    },
  },
  methods: {
    updateValue(val, p) {
      this.$set(this.searchParams, p.name, val)
      this.$emit('updateSearchParam', {name: p.name, val})
      // //todo: doublecheck this refreshes the report data
      //this is a 'hack' to force the update of the computed
      this.update++
    },
  },
  watch: {},
}
</script>
<style scoped></style>
