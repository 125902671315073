<template>
  <div>
    <v-row justify="end">
      <v-col sm="12" md="2">
        <BaseDatePickerWithText
          label="Start Date"
          clearable
          v-model="adhocStartDate"
        >
        </BaseDatePickerWithText>
      </v-col>
      <v-col sm="12" md="2">
        <BaseDatePickerWithText
          label="End Date"
          clearable
          v-model="adhocEndDate"
        >
        </BaseDatePickerWithText>
      </v-col>
      <v-col class="ml-auto" sm="12" md="2">
        <v-text-field
          v-model="adhocText"
          prepend-inner-icon="mdi-file-document-box-search"
          label="Search"
          hint="Application Number, Status Code, Reason Description, Reason Type, County, First Name, Middle Initial, Last Name, Mailing Address 1, Mailing Address 2, Mailing City, Mailing State, Mailing Zip, Parcel Id, Street Address 1, Street Address 2, Property City, Property State, Property Zip"
        ></v-text-field>
      </v-col>
      <v-col sm="12" md="3">
        <v-autocomplete
          v-model="adhocSelectedFields"
          :items="headers"
          item-text="text"
          item-value="value"
          label="Select Columns"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              small
              v-if="index < 5"
              close
              @click:close="removeSelectedField(index)"
            >
              {{ item.text }}</v-chip
            >
            <span v-if="index === 5" class="grey--text caption"
              >(+{{ adhocSelectedFields.length - 5 }} others)</span
            >
          </template>
          <template v-slot:prepend>
            <reorderSelectedFields v-model="adhocSelectedFields">
            </reorderSelectedFields>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="2">
        <h4 @click="toggleFilters" class="pointer primary--text"
          >{{ filterText }}
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="filters">
      <!-- //templateTODO: The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocApplicationId" 
                  :items="availableApplicationId"
                  item-text="Description" 
                  item-value="ID" 
                  label="Application Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <!-- <v-col sm="12" md="3">
          <ApplicationId
              v-model="adhocApplicationId"
              small-chips
              dense
          ></ApplicationId>
              </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocApplicationNumber" 
                  :items="availableApplicationNumber"
                  label="Application Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStatusId" 
                  :items="availableStatusId"
                  item-text="Description" 
                  item-value="ID" 
                  label="Status Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <v-col sm="12" md="3">
        <LuStatus v-model="adhocStatusId" small-chips dense></LuStatus>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStatusCode" 
                  :items="availableStatusCode"
                  label="Status Code"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocReasonId" 
                  :items="availableReasonId"
                  item-text="Description" 
                  item-value="ID" 
                  label="Reason Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <v-col sm="12" md="3">
        <LuReason v-model="adhocReasonId" small-chips dense></LuReason>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocReasonDescription" 
                  :items="availableReasonDescription"
                  label="Reason Description"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocReasonNumber" 
                  :items="availableReasonNumber"
                  item-text="Description" 
                  item-value="ID" 
                  label="Reason Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <!-- <v-col sm="12" md="3">
          <ReasonNumber
              v-model="adhocReasonNumber"
              small-chips
              dense
          ></ReasonNumber>
              </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocReasonType" 
                  :items="availableReasonType"
                  label="Reason Type"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocCountyId" 
                  :items="availableCountyId"
                  item-text="Description" 
                  item-value="ID" 
                  label="County Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <v-col sm="12" md="3">
        <LuCounty v-model="adhocCountyId" small-chips dense></LuCounty>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocCityId" 
                  :items="availableCityId"
                  item-text="Description" 
                  item-value="ID" 
                  label="City Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <!-- <v-col sm="12" md="3">
          <CityId
              v-model="adhocCityId"
              small-chips
              dense
          ></CityId>
              </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocCounty" 
                  :items="availableCounty"
                  label="County"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocPeriodId" 
                  :items="availablePeriodId"
                  item-text="Description" 
                  item-value="ID" 
                  label="Period Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <v-col sm="12" md="3">
        <LuPeriod
          v-model="adhocPeriodId"
          item-text="Period"
          small-chips
          dense
        ></LuPeriod>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocFirstName" 
                  :items="availableFirstName"
                  label="First Name"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocMiddleInitial" 
                  :items="availableMiddleInitial"
                  label="Middle Initial"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocLastName" 
                  :items="availableLastName"
                  label="Last Name"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocMailingAddress1" 
                  :items="availableMailingAddress1"
                  label="Mailing Address 1"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocMailingAddress2" 
                  :items="availableMailingAddress2"
                  label="Mailing Address 2"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocMailingCity" 
                  :items="availableMailingCity"
                  label="Mailing City"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocMailingState" 
                  :items="availableMailingState"
                  label="Mailing State"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocMailingZip" 
                  :items="availableMailingZip"
                  label="Mailing Zip"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocParcelId" 
                  :items="availableParcelId"
                  label="Parcel Id"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStreetAddress1" 
                  :items="availableStreetAddress1"
                  label="Street Address 1"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStreetAddress2" 
                  :items="availableStreetAddress2"
                  label="Street Address 2"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocPropertyCity" 
                  :items="availablePropertyCity"
                  label="Property City"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocPropertyState" 
                  :items="availablePropertyState"
                  label="Property State"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocPropertyZip" 
                  :items="availablePropertyZip"
                  label="Property Zip"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
    </v-row>
  </div>
</template>

<script>
import LuStatus from '@components/select/LuApplicationStatus/LuApplicationStatus-multi-id.vue'
import LuReason from '@components/select/LuReason/LuReason-multi-id.vue'
import LuCounty from '@components/select/LuCounty/LuCounty-multi-id.vue'
import LuPeriod from '@components/select/LuPeriod/LuPeriod-multi-id.vue'

import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  name: 'VwApplicationReportReport',
  components: {
    reorderSelectedFields,
    LuStatus,
    LuReason,
    LuCounty,
    LuPeriod,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    filters: true,
    filterText: '-Hide Filters-',
  }),
  created() {},
  watch: {},
  computed: {
    ...sync('vwApplicationReport', [
      'adhocText',
      'adhocSelectedFields',
      'adhocStartDate',
      'adhocEndDate',
      //templateTODO: uncomment as used in AdHocVwApplicationReportReportParams
      // 'adhocApplicationId',
      // 'adhocApplicationNumber',
      'adhocStatusId',
      // 'adhocStatusCode',
      'adhocReasonId',
      // 'adhocReasonDescription',
      // 'adhocReasonNumber',
      // 'adhocReasonType',
      'adhocCountyId',
      // 'adhocCityId',
      // 'adhocCounty',
      'adhocPeriodId',
      // 'adhocFirstName',
      // 'adhocMiddleInitial',
      // 'adhocLastName',
      // 'adhocMailingAddress1',
      // 'adhocMailingAddress2',
      // 'adhocMailingCity',
      // 'adhocMailingState',
      // 'adhocMailingZip',
      // 'adhocParcelId',
      // 'adhocStreetAddress1',
      // 'adhocStreetAddress2',
      // 'adhocPropertyCity',
      // 'adhocPropertyState',
      // 'adhocPropertyZip',
    ]),
    //templateTODO: custom available lookup computed properties should go here
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    removeSelectedField(index) {
      var list = this.adhocSelectedFields.filter((i) => true)
      list.splice(index, 1)
      this.adhocSelectedFields = list
    },
  },
}
</script>

<style lang="scss"></style>
