<template>
  <BasePanelWithHeader headerText="AdHoc Application Report">
    <BaseServerSyncDataTable
      :headers="filteredHeaders"
      :items="adhocVwApplicationReports"
      :searchTotal="adhocTotal"
      :loading="adhocLoading"
      :searchSortBy.sync="adhocSortBy"
      :searchDescending.sync="adhocDescending"
      :searchPage.sync="adhocPage"
      :searchRowsPerPage.sync="adhocRowsPerPage"
      :show-group-by="!adhocPivotIncludePivot && showGroupBy"
      :groupBy.sync="groupBy"
      :groupDesc.sync="adhocGroupDescending"
    >
      <template v-slot:searchParams>
        <VwApplicationReportParams
          :headers="headers"
        ></VwApplicationReportParams>
        <pivotOptions
          :includePivot.sync="adhocPivotIncludePivot"
          :pivotFirst.sync="adhocPivotFirst"
          :pivotName.sync="adhocPivotName"
          :rowFields.sync="adhocPivotRowFields"
          :columnFields.sync="adhocPivotColumnFields"
          :dataFields.sync="adhocPivotDataFields"
          :excludeFields.sync="adhocPivotExcludeFields"
          :filterFields.sync="adhocPivotFilterFields"
          :filteredHeaders="filteredHeaders"
        ></pivotOptions>
      </template>
      <template v-slot:item.WolfsPaidAmount="{ item }">{{
        item.WolfsPaidAmount | formatMoney
      }}</template>
      <template v-slot:item.TotalHouseholdIncomeReported="{ item }">{{
        item.TotalHouseholdIncomeReported | formatMoney
      }}</template>
      <template v-slot:item.ApplicationApproveAmount="{ item }">{{
        item.ApplicationApproveAmount | formatMoney
      }}</template>
      <template v-slot:item.ThresholdMaximumIncome="{ item }">{{
        item.ThresholdMaximumIncome | formatMoney
      }}</template>
      <template v-slot:item.MedianIncome="{ item }">{{
        item.MedianIncome | formatMoney
      }}</template>
      <template v-slot:item.MedianTax="{ item }">{{
        item.MedianTax | formatMoney
      }}</template>
      <template v-slot:item.AssetMaximum="{ item }">{{
        item.AssetMaximum | formatMoney
      }}</template>
      <template v-slot:item.TaxBillAmount="{ item }">{{
        item.TaxBillAmount | formatMoney
      }}</template>
      <template v-slot:item.HouseholdMaximum="{ item }">{{
        item.HouseholdMaximum | formatMoney
      }}</template>
      <template v-slot:item.PercentageEligible="{ item }">{{
        item.PercentageEligible * 100 + '%'
      }}</template>
      <template v-slot:item.ThresholdIncomePercentage="{ item }">{{
        item.ThresholdIncomePercentage
          ? item.ThresholdIncomePercentage * 100 + '%'
          : ''
      }}</template>
      <template v-slot:item.ThresholdRefundPercentage="{ item }">{{
        item.ThresholdRefundPercentage
          ? item.ThresholdRefundPercentage * 100 + '%'
          : ''
      }}</template>
      <template v-slot:item.HouseholdIncomePercentage="{ item }">{{
        item.HouseholdIncomePercentage
          ? (item.HouseholdIncomePercentage * 100).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) + '%'
          : ''
      }}</template>
    </BaseServerSyncDataTable>
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="downloadAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <!-- //templateTodo: uncomment if this report will use saved queries -->
    <SavedQueries
      reportName="VwApplicationReport"
      :searchParams="adhocParameters"
      :additionalQueries="staticReports"
      @selectQuery="selectQuery"
    />
  </BasePanelWithHeader>
</template>

<script>
import pivotOptions from '@components/reports/pivotOptions'
import { VwApplicationReportsStatic } from '@components/reports/staticReports'
//templateTodo: uncomment if this report will use saved queries
import SavedQueries from '@components/reports/savedQueries'
import VwApplicationReportParams from '@components/reports/VwApplicationReportParams'
import { get, sync, commit, call } from 'vuex-pathify'
import { debounce, range } from 'lodash'
//templateTODO: select default sortBy field
var defaultSortField = 'ApplicationId'
console.devlog(VwApplicationReportsStatic)
export default {
  name: 'VwApplicationReportReport',
  components: {
    pivotOptions,
    VwApplicationReportParams,
    //templateTodo: uncomment if this report will use saved queries
    SavedQueries,
  },
  data: () => ({
    staticReports: VwApplicationReportsStatic,
    headers: [
      {
        text: 'Application Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ApplicationId',
        visible: false,
      },
      {
        text: 'Application Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ApplicationNumber',
        visible: true,
      },
      {
        text: 'Status Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StatusId',
        visible: false,
      },
      {
        text: 'Status Code',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StatusCode',
        visible: true,
      },
      {
        text: 'Current Status Date',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CurrentStatusDate',
        visible: true,
      },
      {
        text: 'Reason Ids',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ReasonIds',
        visible: false,
      },
      {
        text: 'Reason Description',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ReasonDescription',
        visible: true,
      },
      {
        text: 'Reason Numbers',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ReasonNumbers',
        visible: true,
      },
      {
        text: 'Reason Type',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ReasonType',
        visible: true,
      },
      {
        text: 'County Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CountyId',
        visible: false,
      },
      {
        text: 'City Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CityId',
        visible: false,
      },
      {
        text: 'County',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'County',
        visible: true,
      },
      {
        text: 'Median Income',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MedianIncome',
        visible: true,
      },
      {
        text: 'Median Tax',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MedianTax',
        visible: true,
      },
      {
        text: 'Period Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'PeriodId',
        visible: false,
      },
      {
        text: 'Tax Year',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxYear',
        visible: true,
      },
      {
        text: 'Asset Maximum',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AssetMaximum',
        visible: true,
      },
      {
        text: 'Household Maximum',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'HouseholdMaximum',
        visible: true,
      },
      {
        text: 'Percentage Eligible',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'PercentageEligible',
        visible: true,
      },
      {
        text: 'First Name',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'FirstName',
        visible: true,
      },
      {
        text: 'Middle Initial',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MiddleInitial',
        visible: true,
      },
      {
        text: 'Last Name',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'LastName',
        visible: true,
      },
      {
        text: 'Mailing Address 1',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MailingAddress1',
        visible: true,
      },
      {
        text: 'Mailing Address 2',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MailingAddress2',
        visible: true,
      },
      {
        text: 'Mailing City',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MailingCity',
        visible: true,
      },
      {
        text: 'Mailing State',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MailingState',
        visible: true,
      },
      {
        text: 'Mailing Zip',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'MailingZip',
        visible: true,
      },
      {
        text: 'Parcel Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ParcelId',
        visible: true,
      },
      {
        text: 'Street Address 1',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StreetAddress1',
        visible: true,
      },
      {
        text: 'Street Address 2',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StreetAddress2',
        visible: true,
      },
      {
        text: 'Property City',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'PropertyCity',
        visible: true,
      },
      {
        text: 'Property State',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'PropertyState',
        visible: true,
      },
      {
        text: 'Property Zip',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'PropertyZip',
        visible: true,
      },
      {
        text: 'Tax Bill Amount',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxBillAmount',
        visible: true,
      },
      {
        text: 'Total Household Income Reported',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TotalHouseholdIncomeReported',
        visible: true,
      },
      {
        text: 'Application Approve Amount',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ApplicationApproveAmount',
        visible: true,
      },
      {
        text: 'Threshold Type',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ThresholdType',
        visible: true,
      },
      {
        text: 'Threshold Maximum Income',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ThresholdMaximumIncome',
        visible: true,
      },
      {
        text: 'Threshold Income Percentage',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ThresholdIncomePercentage',
        visible: true,
      },
      {
        text: 'Threshold Refund Percentage',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ThresholdRefundPercentage',
        visible: true,
      },
      {
        text: 'Household Income Percentage',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'HouseholdIncomePercentage',
        visible: true,
      },
      {
        text: 'Wolfs Paid Amount',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'WolfsPaidAmount',
        visible: true,
      },
      {
        text: 'Created Date',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CreatedDate',
        visible: false,
      },
      {
        text: 'UpdatedDate',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'UpdatedDate',
        visible: true,
      },
      {
        text: 'Created By Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CreatedById',
        visible: false,
      },
      {
        text: 'Updated By Id',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'UpdatedById',
        visible: false,
      },
      {
        text: 'Created By',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CreatedBy',
        visible: true,
      },
      {
        text: 'UpdatedBy',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'UpdatedBy',
        visible: false,
      },
    ],
  }),
  created() {
    this.adhocSelectedFields = this.headers
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.displayAdhocVwApplicationReports()
  },
  watch: {
    adhocPivotIncludePivot(nval, oval) {
      if (nval) {
        this.adhocGroupBy = null
        this.adhocGroupDescending = null
      }
    },
    adhocSelectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.adhocQueryIsModified = true
        }
      },
      deep: true,
    },
  },
  computed: {
    ...get('vwApplicationReport', [
      'adhocVwApplicationReports',
      'adhocLoading',
      'adhocParameters',
    ]),
    ...sync('vwApplicationReport', [
      'adhocTotal',
      'adhocPage',
      'adhocSortBy',
      'adhocDescending',
      'adhocGroupBy',
      'adhocGroupDescending',
      'adhocRowsPerPage',
      'adhocPivotIncludePivot',
      'adhocPivotFirst',
      'adhocPivotName',
      'adhocPivotRowFields',
      'adhocPivotColumnFields',
      'adhocPivotExcludeFields',
      'adhocPivotDataFields',
      'adhocPivotFilterFields',
      'adhocSelectedFields',
      'adhocQueryIsModified',
      'adhocQueryName',
      'adhocText',
      //templateTODO: uncomment as used in AdHocVwApplicationReportReportParams (if using saved queries)
      // 'adhocApplicationId',
      // 'adhocApplicationNumber',
      'adhocStatusId',
      // 'adhocStatusCode',
      'adhocReasonId',
      // 'adhocReasonDescription',
      // 'adhocReasonNumber',
      // 'adhocReasonType',
      'adhocCountyId',
      // 'adhocCityId',
      // 'adhocCounty',
      'adhocPeriodId',
      // 'adhocFirstName',
      // 'adhocMiddleInitial',
      // 'adhocLastName',
      // 'adhocMailingAddress1',
      // 'adhocMailingAddress2',
      // 'adhocMailingCity',
      // 'adhocMailingState',
      // 'adhocMailingZip',
      // 'adhocParcelId',
      // 'adhocStreetAddress1',
      // 'adhocStreetAddress2',
      // 'adhocPropertyCity',
      // 'adhocPropertyState',
      // 'adhocPropertyZip',
    ]),
    //this can be used for filtering them down
    filteredHeaders: {
      get() {
        let list = this.headers.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.adhocSelectedFields.indexOf(a.value) -
            this.adhocSelectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    showGroupBy() {
      return this.headers.filter((x) => x.groupable).length > 0
    },
    groupBy: {
      get() {
        if (this.adhocGroupBy) {
          return [this.adhocGroupBy]
        } else {
          return []
        }
      },
      set(nval) {
        if (nval) {
          if (Array.isArray(nval)) {
            if (nval.length > 0) {
              this.adhocGroupBy = nval[0]
            } else {
              this.adhocGroupBy = null
            }
          } else {
            this.adhocGroupBy = nval
          }
        } else {
          this.adhocGroupBy = null
        }
      },
    },
  },
  methods: {
    ...call('vwApplicationReport', [
      'displayAdhocVwApplicationReports',
      'downloadAdhoc',
    ]),
    selectQuery(item) {
      if (item && item.Query) {
        let query = JSON.parse(item.Query)
        if (Array.isArray(query.sortDirection)) {
          var descending = query.sortDirection.map((x) =>
            x == 'desc' ? true : false
          )
        } else {
          descending = query.sortDirection == 'desc' ? true : false
        }
        this.adhocDescending = descending
        this.adhocSortBy = query.sortProperty
        this.adhocGroupDescending =
          query.groupByDirection == 'desc' ? true : false
        this.adhocGroupBy = query.groupByProperty
        this.adhocText = query.searchText || ''
        this.adhocQueryName = query.queryName || item.Title
        this.adhocSelectedFields = query.includeFields || []
        if (!query.pivotOptions) {
          this.adhocPivotIncludePivot = query.includePivot
          this.adhocPivotFirst = query.pivotFirst
          this.adhocPivotName = query.pivotName
          this.adhocPivotRowFields = query.rowFields || []
          this.adhocPivotColumnFields = query.columnFields || []
          this.adhocPivotExcludeFields = query.excludeFields || []
          this.adhocPivotDataFields = query.dataFields || []
          this.adhocPivotFilterFields = query.filterFields || []
        } else {
          this.adhocPivotIncludePivot = query.pivotOptions.includePivot
          this.adhocPivotFirst = query.pivotOptions.pivotFirst
          this.adhocPivotName = query.pivotOptions.pivotName
          this.adhocPivotRowFields = query.pivotOptions.rowFields || []
          this.adhocPivotColumnFields = query.pivotOptions.columnFields || []
          this.adhocPivotExcludeFields = query.pivotOptions.excludeFields || []
          this.adhocPivotDataFields = query.pivotOptions.dataFields || []
          this.adhocPivotFilterFields = query.pivotOptions.filterFields || []
        }
        // most likely these will change over time, and shouldn't be loaded as part of the query
        //  this.adhocStartDate = query.startDate
        //  this.adhocEndDate = query.endDate
        //templateTODO: uncomment as used in AdHocVwApplicationReportReportParams (if using saved queries)
        // this.adhocApplicationId = query.applicationId || []
        // this.adhocApplicationNumber = query.applicationNumber || []
        this.adhocStatusId = query.statusId || []
        // this.adhocStatusCode = query.statusCode || []
        this.adhocReasonId = query.reasonId || []
        // this.adhocReasonDescription = query.reasonDescription || []
        // this.adhocReasonNumber = query.reasonNumber || []
        // this.adhocReasonType = query.reasonType || []
        this.adhocCountyId = query.countyId || []
        // this.adhocCityId = query.cityId || []
        // this.adhocCounty = query.county || []
        this.adhocPeriodId = query.periodId || []
        // this.adhocFirstName = query.firstName || []
        // this.adhocMiddleInitial = query.middleInitial || []
        // this.adhocLastName = query.lastName || []
        // this.adhocMailingAddress1 = query.mailingAddress1 || []
        // this.adhocMailingAddress2 = query.mailingAddress2 || []
        // this.adhocMailingCity = query.mailingCity || []
        // this.adhocMailingState = query.mailingState || []
        // this.adhocMailingZip = query.mailingZip || []
        // this.adhocParcelId = query.parcelId || []
        // this.adhocStreetAddress1 = query.streetAddress1 || []
        // this.adhocStreetAddress2 = query.streetAddress2 || []
        // this.adhocPropertyCity = query.propertyCity || []
        // this.adhocPropertyState = query.propertyState || []
        // this.adhocPropertyZip = query.propertyZip || []
      } else {
        this.resetQuery()
      }

      if (item && item.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
      }
      this.$nextTick(() => {
        this.queryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.queryIsModified = false
    },
  },
}
</script>

<style lang="scss"></style>
